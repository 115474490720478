import React, { useEffect, useState } from 'react'
import cascading from '../Assests/css/introanim.module.css'
import Images from '../Assests/images/images'
export default function Introanim({ sendAnimdata }) {
    const [initial,setInitial] = useState(false);
    const [logoanim,setLogoanim] = useState(false);
    const [imagechange,setImagechange] = useState(false);
    const [logoshow,setLogoshow] = useState(false);

         useEffect(()=>{
            setTimeout(() => {
                setInitial(true)
            }, 600);
            setTimeout(() => {
                setLogoshow(true)
            }, 1200);
            setTimeout(() => {
                setLogoanim(true) 
            }, 2300);
            setTimeout(() => {
                setImagechange(true)
            
            }, 3800);
            setTimeout(() => {
                sendAnimdata(true)
            }, 5000);
         })

    // const pageopen = () => {
    //     setLogoanim(true)
          
        
            
   
  
    //  }
       


  return (
    <div className={`${cascading.introanim} ${initial && cascading.introanimactive} `}>
         <img src={Images.snow} className={`img-fluid ${cascading.snowimg}`} alt='snow'/>
         {initial &&
           <div className={`${cascading.logosec} ${logoshow && cascading.logoopen}  ${logoanim && cascading.logosecanim}`} 
>
           
<img src={imagechange ? Images.bluelogo1 :Images.whitelogo} className={`img-fluid ${cascading.logoimg} `} alt='logo'/>
{/* <img src={Images.whitelogo} className={`img-fluid ${cascading.logoimg} ${imagechange && cascading.logoimgchange}`} alt='logo'/> */}
           </div>
}
         


    </div>
  )
}
