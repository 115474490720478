import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';
import Home from './Views/Home';

function App() {
  return (
         <>
         <BrowserRouter>
         <Routes>
         <Route path='/' element={<Home/>} />
         </Routes>

         </BrowserRouter>
         </>
  );
}

export default App;
