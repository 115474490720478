import React, { useEffect, useState } from 'react'
import Header from '../Common/Header';
import AOS from 'aos';
import 'aos/dist/aos.css';
import cascading from '../Assests/css/home.module.css'
import Images from '../Assests/images/images';
import Accordion from 'react-bootstrap/Accordion';
import { IoMail } from "react-icons/io5";
import { FaFacebook, FaLinkedin, FaPhoneAlt } from "react-icons/fa";
import { FaLocationDot, FaXTwitter } from "react-icons/fa6";
import { Link } from 'react-router-dom';
import Sidemenu from '../Common/sidemenu';
import Introanim from '../Common/Introanim';
export default function Home() {

  const[menuOpen,setMenuOpen] = useState(false)
  const[logoanim ,setLogoanim] = useState(false) 
const[inanim ,setInanim] = useState(true) 
  const [visioncard,setVisioncard]=useState([{
    img:Images.vision1,
    desc:"Blue Icee Giant Mini World Project is a collection of passionate people experimenting our way toward an ambitious mission and vision of ecologically sustainable living. We are balancing economic and social sustainability in the context of our ecological commitments. As a small intentional community intent on growing to be a village of several hundred, we are an experiment in cooperative culture. "

  },
  {
    img:Images.vision2,
    desc:"We do these things in order to deepen interpersonal relationships, challenge the patterns and impacts of sexism, racism and other forms of unearned privilege, and strengthen the interconnected web of community. The inner sustainability* of our community depends on shared purpose, interpersonal connection, individual growth and building internal resilience through play, work, and celebration."

  },
  {
    img:Images.vision3,
    desc:"We value the benefits of engaging with the wider economic culture while also educating ourselves and exploring alternative economic models to support our local economy. Developing a strong local economy is essential to our community’s growth and stability. Our ecological covenants are a source of guidance and support as we seek to balance ecological sustainability and sustainable economics."

  }

])
  
  const [solutionsec, setSolutionsec] = useState([{
    head: "Smart Property Management Solutions",
    hint: "Enhanced security for your home and access to your society !!!",
    desc: "More Secure: Make your society more secure by controlling and monitoring visitor movement. More connected: Increase to your society facilities, neighbours and the community. More private: Whatever is private to you, stays that way.",
    img: Images.property
  },
  {
    head: "Smart Infrastructure Management Solutions",
    hint: "Digitally transform your society’s infrastructure !!!",
    desc: "Make your housing society smarter with our infrastructure management solution. We help you automate and optimize operations to save up to 15% on monthly utility spends for your society.",
    img: Images.infra
  },
  {
    head: "Smart Community Management Solutions",
    hint: "Make a place Worth Live in !!!",
    desc: "The Community Managers play a vital role in empowering our communities, co-creating their neighbourhood for the greater good of everyone.",
    img: Images.community
  }])
  const [devcontent, setDevcontent] = useState([{
    img: Images.environment,
    head: "Environment",
    desc: "Urban Planning and environmental sustainability drive our real estate projects"
  },
  {
    img: Images.tech,
    head: "Technology",
    desc: "Technology for residential societies with solutions for networks, home automations, security, infrastructure management and much more."
  },
  {
    img: Images.people,
    head: "People",
    desc: "Inclusive and collective culture driven by connected communities powered by planet’s technology and community managers."
  },
  {
    img: Images.architechure,
    head: "Architecture",
    desc: "High quality buildings using innovative architecture and sustainable methodologies"
  },
  ])
  const [smartcont, setSmartcont] = useState([{
    name: "Organic Vegetables",
    img: Images.vegetables

  },
  {
    name: "Organic Shrimps",
    img: Images.shrimps

  },
  {
    name: "Organic Fish",
    img: Images.fish

  }])
  const [smartenv, setSmartenv] = useState([{
    desc: "Can Install at any location"
  },
  {
    desc: "Recycled Steel/Timber Structure"
  },
  {
    desc: "No Need Water and Power at location."
  },
  {
    desc: "Zero Carbon Footprint"
  },
  {
    desc: "Own Water & Electricity"
  },
  {
    desc: "Disaster Proof Building Structure"
  },

  ])
  const [smartSus, setSmartSus] = useState([{
    desc: "2, 3 BRHK Smart affordable Home"
  },
  {
    desc: "A home comes with two guaranteed employment ."
  },
  {
    desc: "1,200kg vegetable per month"
  },
  {
    desc: "100kg Fish per month"
  },
  {
    desc: "Air water for the Bio Toile"
  },
  {
    desc: "Bio Energy, Organic Fertilizer"
  },
  {
    desc: "Drinking Water & Irrigation Water"
  },

  ])
  const [houseenv, setHouseenv] = useState([{
    desc: "Zero Carbon Foot Print"
  },
  {
    desc: "Greenhouse and Farm House build from locally available materials"
  },
  {
    desc: "No Chemicals"
  },
  {
    desc: "No Pesticides"
  },
  {
    desc: "No Chemical Fertilizer"
  },
  {
    desc: "100% water is recycled"
  },
  {
    desc: "No soil contamination"
  },

  ])
  const [houseSus, setHouseSus] = useState([{
    desc: "Self Sustainable Smart farms"
  },
  {
    desc: "Produces own Water for irrigation and drinking for the Facility."
  },
  {
    desc: "Organic Vegetables, Fish and Poultry"
  },
  {
    desc: "300 times more yield"
  },
  {
    desc: "100% No wastage (Waste into Profit)"
  },
  {
    desc: "Produce FOOD, ENERGY and WATER from a single source."
  },


  ])
  const [houseenv1, setHouseenv1] = useState([{
    desc: "Zero Carbon Foot Print"
  },
  {
    desc: "Greenhouse and Farm House build from locally available materials"
  },
  {
    desc: "No Chemicals"
  },
  {
    desc: "No Pesticides"
  },
  {
    desc: "No Chemical Fertilizer"
  },
  {
    desc: "100% water is recycled"
  },
  {
    desc: "No soil contamination"
  },

  ])
  const [houseSus1, setHouseSus1] = useState([{
    desc: "Self Sustainable Smart farms"
  },
  {
    desc: "Produces own Water for irrigation and drinking for the Facility."
  },
  {
    desc: "Organic Vegetables, Fish and Poultry"
  },
  {
    desc: "300 times more yield"
  },
  {
    desc: "100% No wastage (Waste into Profit)"
  },
  {
    desc: "Produce FOOD, ENERGY and WATER from a single source."
  },


  ])
  const [kitchenenv, setKitchenenv] = useState([{
    desc: "Plug and Play Unit"
  },
  {
    desc: "No need labor"
  },
  {
    desc: "Good for Kitchen & Dining Hall"
  },
  {
    desc: "Beautiful design unit"
  },
  {
    desc: "Custom Design Unit (Option)"
  },
  {
    desc: "Zero Carbon Foot Print"
  },


  ])
  const [kitchenSus, setkitchenSus] = useState([{
    desc: "Daily Vegetables for a single Family"
  },
  {
    desc: "3 – 20 Fish Monthly"
  },
  {
    desc: "Only 5W motor (Solar option)"
  },
  {
    desc: "Absorbed CO2 from the Room"
  },
  {
    desc: "Emit O2 into the Room"
  },
  {
    desc: "100% Organic Fresh Vegetables"
  },
  {
    desc: "Save money from costly vegetables"
  },



  ])
  const [waterenv, setWaterenv] = useState([{
    desc: "No Need for Foundation"
  },
  {
    desc: "Can Install at any location"
  },
  {
    desc: "Recycled Steel/Timber Structure"
  },
  {
    desc: "Aquatic and Marine Restoration"
  },
  {
    desc: "Bamboo Plantation"
  },
  {
    desc: "Innovative & Sustainable Technology"
  },
  {
    desc: "Zero Carbon Footprint"
  },


  ])
  const [waterSus, setWaterSus] = useState([{
    desc: "200 – 1000kWh Solar Power"
  },
  {
    desc: "Smart Battery Systems"
  },
  {
    desc: "Heating & Cooling from Waste"
  },
  {
    desc: "Gravity Aeration – Save Energy"
  },
  {
    desc: "20,000L per day – Single Unit"
  },
  {
    desc: "100,000L per day – Tri Unit"
  },
  {
    desc: "80% improve in Aquatic life"
  },
  {
    desc: "Self Sustainable Unit"
  },



  ])
  const [giantcont, setGiantcont] = useState([{
    img: Images.giant1
  },
  {
    img: Images.giant2
  },
  {
    img: Images.giant3
  }])
  const [giantenv, setGiantenv] = useState([{
    desc: "Eco Pilgrimage Resorts & Retails"
  },
  {
    desc: "No Chemicals, No Structures"
  },
  {
    desc: "Instant and one year delivery"
  },
  {
    desc: "Produce Own Water & Power"
  },
  {
    desc: "Green Building Regulations"
  },
  {
    desc: "EHS (Environment, Health, Safety) regulations"
  },



  ])
  const [giantSus, setGiantSus] = useState([{
    desc: "Self Sustainable Temple World"
  },
  {
    desc: "Produce water for both drinking and irrigation for the entire Facility"
  },
  {
    desc: "Organic Vegetables, Fruits & Flower"
  },
  {
    desc: "Vedic Flower, Fruits & Foods"
  },
  {
    desc: "Bio Energy & Solar Energy"
  },
  {
    desc: "Create Self-Sustainable Jobs"
  },
  {
    desc: "Waste into Profits"
  },



  ])
  const [giantenv1, setGiantenv1] = useState([{
    desc: "No Chemicals, No Structures"
  },
  {
    desc: "Instant and one year delivery"
  },
  {
    desc: "Produce Own Water & Power"
  },
  {
    desc: "Green Building Regulations"
  },
  {
    desc: "EHS (Environment, Health, Safety) regulations"
  },
  {
    desc: "Eco Tourism Resorts & Retails"
  },



  ])
  const [giantSus1, setGiantSus1] = useState([{
    desc: "Produce water for both drinking and irrigation for the entire Facility"
  },
  {
    desc: "Organic Vegetables, Fruits & Flower"
  },
  {
    desc: "Organic Fish & Shrimps"
  },
  {
    desc: "Bio Energy & Solar Energy"
  },
  {
    desc: "Create Self-Sustainable Jobs"
  },
  {
    desc: "Waste into Profits"
  },
  {
    desc: "Self Sustainable Lake & Themed Park"
  },



  ])
  const [living, setLiving] = useState([
    {
      img: Images.accimg1,
      title: "An Environment of Calm",
      list: ["Green Open Spaces", "Over 7795 Trees of 60+ Species", "Therapeutic Walk & Herb Garden", "90,000 Shrubs and Counting", "Birds & Butterflies of 46 Species"]
    },
    {
      img: Images.accimg2,
      title: "An Environment of Care",
      list: ["Yoga Pavilion", "Acupressure Pathway", "Senior Citizen’s Area", "Dedicated Cycling Track & Walkways"]
    },
    {
      img: Images.accimg3,
      title: "An Environment of Activity",
      list: ["Cricket Pitch", "Tennis & Basketball Court", "Swimming Pool & Gym", "Adventure Sports & Many More"]
    },
    {
      img: Images.accimg4,
      title: "An Environment of Safety",
      list: ["Fire Station", "CCTV Cameras", "Ambulance Services", "24/7 Security"]
    },
    {
      img: Images.accimg5,
      title: "An Environment of Learning",
      list: ["Fully Functional Educational Institute"]
    },
    {
      img: Images.accimg6,
      title: "An Environment of Innocence",
      list: ["Children’s Play Area", "Kid’s Pool", "Mini Football Court", "Multipurpose Play Court with Skating Rink"]
    },
    {
      img: Images.accimg7,
      title: "An Environment of Togetherness",
      list: ["Community of 5,000+ Happy Residents", "United Festive Celebrations", "Community Building Activities (Sunday Wellness,United Sky Theatre, etc.)"]
    },
    {
      img: Images.accimg8,
      title: "An Environment of Wellness",
      list: ["All type of Medical treatment of Homeopathy,  Ayurveda, Naturopathy and Siddha"]
    },
    {
      img: Images.accimg9,
      title: "An Environment of Closeness",
      list: ["Easy access to airport and bus terminals", "Helipad for landing helicopters"]
    },


  ])
  const [kitchenimg,setKitchenimg] = useState([
    Images.kitchenidea1,
    Images.kitchenidea2,
    Images.kitchenidea3,
    Images.kitchenidea4,
    Images.kitchenidea5,
    Images.kitchenidea6,

  ])
  const getid = (e)=>{
    const section = document.getElementById(e.target.id);
    console.log(section,"sectionsection");
    
    if (section) {
      // section.scrollIntoView({ behavior: "smooth" ,top:70});
      const topOffset = 70; // Set the offset value here
    const elementPosition = section.getBoundingClientRect().top + window.scrollY - topOffset;

    window.scrollTo({
      top: elementPosition,
      behavior: "smooth",
    });

      if(e.target.id =="PROJECTS"){
        setMenuOpen(true)
      }
      else{
        setMenuOpen(false)
      }
    }
    
  }
  const getAnimdata = (data)=>{
    console.log(data,"logoanim");
    setLogoanim(data)

    
  }
  useEffect(()=>{

    if(logoanim){
      document.body.style.overflow = 'auto';  
    
        setInanim(false)

     
    }
    else{
      document.body.style.overflow = 'hidden';  
    }
  },[logoanim])
  useEffect(() => {
    AOS.init({disable: 'mobile'});
}, [])
  return (
    <div className={`${cascading.landingpage}`}>
         
     
      <Header />
      {menuOpen &&
      <Sidemenu show={menuOpen} handleClose={()=>setMenuOpen(false)} getid={(id)=>getid(id)}/>
}
      <button className={`btn ${cascading.sidemenubtn}`} onClick={() => setMenuOpen(true)}>Menu</button>
      {inanim ?
      <div className={`${logoanim && cascading.animclose} ${cascading.animlayer}`}>
      <Introanim sendAnimdata={getAnimdata}/>
      </div>
      :
     
      <>
      <section id="HOME" className={`${cascading.bannersec} d-flex align-items-center justify-content-center`}>
        <img src={Images.bannergif} className={`img-fluid ${cascading.bannergif}`} alt='images' />
        <img src={Images.mergeimg} className={`img-fluid ${cascading.mergeimg}`} alt='images' />
        <div className={`container customcontainer`}>
          <div className={`row ${cascading.bannerrow}`} data-aos="zoom-in-up" data-aos-easing="linear"
     data-aos-duration="500">
            <div className={`col-12 justify-content-center align-items-center`}>

              <p className={`${cascading.minihead}`} >My Smart World</p>
              <h1 className={`${cascading.heading}`}>BLUE ICEE GIANT MINI WORLD</h1>
              <p className={`${cascading.desc}`}>We strive to be good stewards of our land, with much of our acreage reserved as wildlife habitat. In the grasslands we are reintroducing native prairie plants to help revitalize our region’s biodiversity. We’ve planted over 10,000 trees in an effort to restore our land to its precolonial ecology, to stabilize the riparian zone, and to provide a sustainable source of wood for our community in years to come.</p>
              <div className={`text-center mt-5`}>
                <button className={`btn themebtn`}>Start For Free</button>


              </div>
            </div>

          </div>

        </div>

      </section>
      <section id='ABOUT US' className={`${cascading.aboutsec}  ${cascading.secondsec}  ${cascading.sectionend} mb-5`}>
        <div className={`container customcontainer`}>
          <div className={`row `}>
            <div className={`col-12 mb-5`}>
              <div className={`${cascading.secbanner}`} data-aos="fade-up"  data-aos-offset="200" >
                <img src={Images.aboutbanner} className={`img-fluid ${cascading.secbannerimg}`} alt='aboutbanner' />
                <div className={`${cascading.contentsec}`}>
                  <h2 className={`${cascading.sechead}`}>About Us</h2>
                  <p className={`${cascading.secdesc}`}>Blue Icee Giant Mini World</p>
                </div>

              </div>
            </div>
            <div className={`col-12 mb-5`}>
              <div className={`${cascading.detailcard}`} data-aos="zoom-in-up"  data-aos-offset="200">
                <div className={`row align-items-center`}>
                  <div className={`col-lg-6 col-xxl-7`}>
                    <h3 className={`${cascading.cardhead}`}>About Blue Icee Giant</h3>
                    <p className={`${cascading.carddesc}`}>
                      At Blue Icee Giant Mini World Project, we understand how difficult it can be to live sustainably and responsibly within modern World. We believe that we can work to build a healthy alternative: a social structure that is both non-exploitative and vibrant. As our village grows, we see this ideal take shape more clearly every day: a diverse range of people living ecologically sound lives in a community that truly serves as an example of positive human action within the natural world
                    </p>

                  </div>
                  <div className={`col-lg-6 col-xxl-5 d-flex align-items-center justify-content-xl-end`}>
                    <div className={`${cascading.cardimg}`}>
                      <img src={Images.aboutimg1} className={`img-fluid`} alt='aboutimg1' />
                    </div>

                  </div>

                </div>

              </div>

            </div>
            <div className={`col-12 mb-5`}>
              <div className={`${cascading.detailcard}`} data-aos="zoom-in-down"  data-aos-offset="200">
                <div className={`row align-items-center  `}>

                  <div className={`col-lg-6 col-xxl-5 mb-3  d-flex align-items-center justify-content-xl-start`}>
                    <div className={`${cascading.cardimg}`}>
                      <img src={Images.aboutimg2} className={`img-fluid`} alt='aboutimg2' />
                    </div>

                  </div>
                  <div className={`col-lg-6 col-xxl-7 `}>
                    <h3 className={`${cascading.cardhead}`}>About Blue Icee Giant</h3>
                    <p className={`${cascading.carddesc}`}>
                      Our Project Plan of 7000 Acres is being developed by MACHO PROPERTIES PRIVATE LIMITED. We are now deep into pioneering constructing buildings while continuing to plan and develop community structure. There is an ever-increasing emphasis on internal economy, which includes bartering. Eventually, we see 1,000 to 5,000 people living in our Blue Icee Giant Mini World Project, with businesses and homes surrounding the town center.
                    </p>
                    <p className={`${cascading.carddesc}`}>
                      As you might expect, ecological sustainability is the primary focus of our long-term vision and our daily lives. Residents agree to follow ecological covenants and sustainability guidelines. We build our homes using alternative techniques such as straw bale and cob, powering them with renewable energy from the Sun and wind. Vehicles at Blue Icee Giant are owned cooperatively and powered by electricity and biodiesel. Overall, we eat an ever-increasing amount of local, organic, and in-season foods including many home-grown vegetables.
                    </p>

                  </div>
                  <div className={`col-12 mt-5`}>
                    <p className={`${cascading.carddesc}`}>
                      We strive to be good stewards of our land, with much of our acreage reserved as wildlife habitat. In the grasslands we are reintroducing native prairie plants to help revitalize our region’s biodiversity. We’ve planted over 10,000 trees in an effort to restore our land to its precolonial ecology, to stabilize the riparian zone, and to provide a sustainable source of wood for our community in years to come.
                    </p>
                    <p className={`${cascading.carddesc}`}>
                      Diversity is an important element within our human population as well. Our Blue Icee Giant Mini World Project is composed of individuals, families, and an income-sharing community. We look forward to having other sub-communities join us and we encourage the development of co-housing and cooperatives.
                    </p>
                    <p className={`${cascading.carddesc}`}>
                      In addition to being a wonderful home for us, Blue Icee Giant Mini World Project is a model for social change. Outreach and education are integral to our mission. Rather than isolating ourselves completely from the mainstream, we promote DR as a viable alternative. We enjoy sharing discoveries and ideas of sustainable living with people who have a wide variety of lifestyles.
                    </p>

                  </div>

                </div>

              </div>

            </div>


          </div>

        </div>

      </section>
      <section id="VISION" className={`${cascading.visionsec} ${cascading.sectionend} mb-5`}>
        <div className={`container customcontainer`}>
          <div className={`row `}>
            <div className={`col-12 mb-5`}>
              <div className={`${cascading.secbanner}`} data-aos="fade-up"  data-aos-offset="200" >
                <img src={Images.aboutbanner} className={`img-fluid ${cascading.secbannerimg}`} alt='aboutbanner' />
                <div className={`${cascading.contentsec}`}>
                  <h2 className={`${cascading.sechead}`}>VISION</h2>
                  <p className={`${cascading.secdesc}`}>Blue Icee Giant Mini World</p>
                </div>

              </div>
            </div>
            <div className={`col-12 mb-5`}>
              <div className={`row`}>
                {visioncard.map((e)=>(
  <div className={`col-lg-4 mb-3`}>
  <div className={`${cascading.learncard}`}>
  <img src={e.img} className={`img-fluid ${cascading.learnimg} mb-4`} alt={e.img.name}/>
    <p className={`${cascading.desc}`}>{e.desc}</p>
  </div>
    </div>
                ))}

              </div>

            </div>



          </div>

        </div>

      </section>
      <section id="MISSION" className={`${cascading.missionsec} ${cascading.sectionend} mb-5`}>
        <div className={`container customcontainer`}>
          <div className={`row `}>
            <div className={`col-12 mb-5`}>
              <div className={`${cascading.secbanner}`} data-aos="fade-up"  data-aos-offset="200" >
                <img src={Images.missionbanner} className={`img-fluid ${cascading.secbannerimg}`} alt='aboutbanner' />
                <div className={`${cascading.contentsec}`}>
                  <h2 className={`${cascading.sechead}`}>MISSION</h2>
                  <p className={`${cascading.secdesc}`}>Blue Icee Giant Mini World</p>
                </div>

              </div>
            </div>
            <div className={`col-12 mb-5`}>
              <div className={`${cascading.detailcard}`} data-aos="zoom-in-up"  data-aos-offset="200">
                <div className={`row align-items-center`}>
                  <div className={`col-lg-6 col-xxl-7`}>
                    <h3 className={`${cascading.cardhead}`}>Mission Blue Icee Giant</h3>
                    <p className={`${cascading.carddesc}`}>
                      To create a society, the size of a small town or village, made up of individuals and communities of various sizes and social structures, which allows and encourages its members to live sustainably.
                    </p>
                    <p className={`${cascading.carddesc}`}>
                      To encourage this sustainable society to grow to have the size and recognition necessary to have an influence on the global community by example, education, and research.
                    </p>
                    <p className={`${cascading.carddesc}`}>
                      *Sustainably: In such a manner that, within the defined area, no resources are consumed faster than their natural replenishment, and the enclosed system can continue indefinitely without degradation of its internal resource base or the standard of living of the people and the rest of the ecosystem within it, and without contributing to the non-sustainability of ecosystems outside.
                    </p>

                  </div>
                  <div className={`col-lg-6 col-xxl-5 d-flex align-items-center justify-content-xl-end`}>
                    <div className={`${cascading.cardimg}`}>
                      <img src={Images.missionimg} className={`img-fluid`} alt='aboutimg1' />
                    </div>

                  </div>

                </div>

              </div>

            </div>



          </div>

        </div>

      </section>
      <section id="OUR SOLUTIONS" className={`${cascading.solutionsec} ${cascading.sectionend} mb-5`}>
        <div className={`container customcontainer`}>
          <div className={`row `}>
            <div className={`col-12 mb-5`}>
              <div className={`${cascading.secbanner}`} data-aos="fade-up"  data-aos-offset="200" >
                <img src={Images.aboutbanner} className={`img-fluid ${cascading.secbannerimg}`} alt='aboutbanner' />
                <div className={`${cascading.contentsec}`}>
                  <h2 className={`${cascading.sechead}`}>Experience smarter living with our solutions!</h2>
                  <p className={`${cascading.secdesc}`}>Blue Icee Giant Mini World</p>
                </div>

              </div>
            </div>
            <div className={`col-12 mb-5`}>
              <div className={`row`}>
                {solutionsec.map((e) => (
                  <div className={`col-md-6 col-lg-4 mb-3`}>
                    <div className={`${cascading.learncard} ${cascading.solcard}`}>
                      <img src={e.img} className={`img-fluid ${cascading.solimg} mb-4`} alt={e.img.name} />
                      <h3 className={`${cascading.head}`}>{e.head}</h3>
                      <p className={`${cascading.hint} my-4`}>{e.hint}</p>

                      <p className={`${cascading.desc}`}>{e.desc}</p>
                    </div>

                  </div>
                ))}


              </div>


            </div>



          </div>

        </div>

      </section>
      <section id="OUR DEVELOPMENT" className={`${cascading.devsec} ${cascading.sectionend} mb-5`}>
        <div className={`container customcontainer`}>
          <div className={`row `}>
            <div className={`col-12 mb-5`}>
              <div className={`${cascading.secbanner}`} data-aos="fade-up"  data-aos-offset="200">
                <img src={Images.devbanner} className={`img-fluid ${cascading.secbannerimg}`} alt='aboutbanner' />
                <div className={`${cascading.contentsec}`}>
                  <h2 className={`${cascading.sechead}`}>Smart Sustainable Mini World Project Development</h2>
                  <p className={`${cascading.secdesc}`}>The Next Generation of</p>
                </div>

              </div>
            </div>
            <div className={`col-12 mb-5`}>
              <h3 className={`${cascading.headings}`}>Four Areas, One Goal</h3>
              <p className={`${cascading.headingdesc}`}>
                Blue Icee Giant Mini World Project unique & innovative approach embeds smart technology-enabled solutions into affordable housing. We design and build neighbourhoods that integrate Self Sustainable technologies and social innovation, along with world-class architectural expertise.
              </p>
              <p className={`${cascading.headingdesc}`}>
                We use the four pillars of innovation around people, technology, architecture and environment to create unique spaces for our residents.
              </p>

            </div>
            <div className={`col-12 mb-5`}>
              <div className={`row`}>
                <div className={` col-xl-7 mb-3`}>
                  <div className={`${cascading.frameimg}`}>
                    <img src={Images.devimg} className={`img-fluid`} alt='devbanner' />
                  </div>


                </div>
                <div className={` col-xl-5`}>
                  <div className='row'>
                    {devcontent.map((e) => (
                      <div className={`col-md-6  mb-3`}>
                        <div className={`${cascading.learncard} ${cascading.devcard} d-flex justify-content-between flex-column`}>
                          <div>
                            <h3 className={`${cascading.head}`}>{e.head}</h3>
                            <p className={`${cascading.desc}`}>{e.desc}</p>
                          </div>
                          <img src={e.img} className={`img-fluid ${cascading.devimg} mt-4`} alt={e.img} />
                        </div>

                      </div>
                    ))}
                  </div>

                </div>



              </div>


            </div>



          </div>

        </div>

      </section>
      <section id="SMART community" className={`${cascading.comsec} ${cascading.sectionend} mb-5`}>
        <div className={`container customcontainer`}>
          <div className={`row `}>
            <div className={`col-12 mb-5`}>
              <div className={`${cascading.secbanner}`} data-aos="fade-up"  data-aos-offset="200">
                <img src={Images.combanner} className={`img-fluid ${cascading.secbannerimg}`} alt='aboutbanner' />
                <div className={`${cascading.contentsec}`}>
                  <h2 className={`${cascading.sechead}`}>Smart Community</h2>
                  <p className={`${cascading.secdesc}`}>RESIDENTIAL AGRI-DREAM VILLAS</p>
                </div>

              </div>
            </div>
            <div className={`col-12 mb-5`}>
              <h3 className={`${cascading.headings}`}>Four Areas, One Goal</h3>
              <p className={`${cascading.headingdesc}`}>
                Blue Icee Giant Mini World Project unique & innovative approach embeds smart technology-enabled solutions into affordable housing. We design and build neighbourhoods that integrate Self Sustainable technologies and social innovation, along with world-class architectural expertise.
              </p>
              <p className={`${cascading.headingdesc}`}>
                We use the four pillars of innovation around people, technology, architecture and environment to create unique spaces for our residents.
              </p>

            </div>
            <div className={`col-12 mb-5`}>
              <div className={`row`}>
                {smartcont.map((e) => (
                  <div className={`col-md-6 col-lg-4 mb-3`}>
                    <div className={`${cascading.smartframe}`}>
                      <img src={e.img} className={`img-fluid`} alt={e.name} />
                      <p className={`${cascading.smartname}`}>{e.name}</p>
                    </div>


                  </div>
                ))}


              </div>

            </div>
            <div className={`col-12 mb-5`}>
              <div className={` ${cascading.tabcardstyle}`} data-aos="zoom-in-up"  data-aos-offset="200">
                <div className={`row`}>
                  <div className={`col-lg-6 mb-3 px-0 ${cascading.leftside}`}>
                    <h3 className={`${cascading.tabhead}`}>Environment Feature</h3>

                    <ul className={`${cascading.listsec}`}>
                      {smartenv.map((e, i) => (
                        <li className={`d-flex align-items-center gap-4`}>
                          <p className={`${cascading.listcount}`}>{i > 9 ? i + 1 : "0" + (i + 1)}</p>
                          <p className={`${cascading.listdesc}`}>{e.desc}</p>
                        </li>
                      ))}

                    </ul>

                  </div>

                  <div className={`col-lg-6 mb-3 px-0`}>
                    <h3 className={`${cascading.tabhead}`}>Sustainability feature</h3>
                    <ul className={`${cascading.listsec}`}>
                      {smartSus.map((e, i) => (
                        <li className={`d-flex align-items-center gap-4`}>
                          <p className={`${cascading.listcount}`}>{i > 9 ? i + 1 : "0" + (i + 1)}</p>
                          <p className={`${cascading.listdesc}`}>{e.desc}</p>
                        </li>
                      ))}

                    </ul>
                  </div>

                </div>

              </div>

            </div>



          </div>

        </div>

      </section>
      <section className={`${cascading.comsec} ${cascading.sectionend} mb-5`}>
        <div className={`container customcontainer`}>
          <div className={`row `}>
            <div className={`col-12 mb-5`}>
              <div className={`${cascading.secbanner}`} data-aos="fade-up"  data-aos-offset="200">
                <img src={Images.combanner1} className={`img-fluid ${cascading.secbannerimg}`} alt='aboutbanner' />
                <div className={`${cascading.contentsec}`}>
                  <h2 className={`${cascading.sechead}`}>Smart Community</h2>
                  <p className={`${cascading.secdesc}`}>RESIDENTIAL AGRI-DREAM VILLAS</p>
                </div>

              </div>
            </div>


            <div className={`col-12 mb-5`}>
              <div className={` ${cascading.tabcardstyle}`} data-aos="zoom-in-up"  data-aos-offset="200">
                <div className={`row`}>
                  <div className={`col-lg-6 mb-3 px-0 ${cascading.leftside}`}>
                    <h3 className={`${cascading.tabhead}`}>Environment Feature</h3>

                    <ul className={`${cascading.listsec}`}>
                      {smartenv.map((e, i) => (
                        <li className={`d-flex align-items-center gap-4`}>
                          <p className={`${cascading.listcount}`}>{i > 9 ? i + 1 : "0" + (i + 1)}</p>
                          <p className={`${cascading.listdesc}`}>{e.desc}</p>
                        </li>
                      ))}

                    </ul>

                  </div>

                  <div className={`col-lg-6 mb-3 px-0`}>
                    <h3 className={`${cascading.tabhead}`}>Sustainability feature</h3>
                    <ul className={`${cascading.listsec}`}>
                      {smartSus.map((e, i) => (
                        <li className={`d-flex align-items-center gap-4`}>
                          <p className={`${cascading.listcount}`}>{i > 9 ? i + 1 : "0" + (i + 1)}</p>
                          <p className={`${cascading.listdesc}`}>{e.desc}</p>
                        </li>
                      ))}

                    </ul>
                  </div>

                </div>

              </div>

            </div>



          </div>

        </div>

      </section>
      <section id="SMART green house" className={`${cascading.housesec} ${cascading.sectionend} mb-5`}>
        <div className={`container customcontainer`}>
          <div className={`row `}>
            <div className={`col-12 mb-5`}>
              <div className={`${cascading.secbanner}`} data-aos="fade-up"  data-aos-offset="200">
                <img src={Images.greenhousebanner} className={`img-fluid ${cascading.secbannerimg}`} alt='greenhousebanner' />
                <div className={`${cascading.contentsec}`}>
                  <h2 className={`${cascading.sechead}`}>SMART GREENHOUSE</h2>
                  <p className={`${cascading.secdesc}`}>Self Sustainable Village</p>
                </div>

              </div>
            </div>
            <div className={`col-12 mb-5`}>
              <img src={Images.greenhouseimg} className={`img-fluid ${cascading.greenhouseimg}`} alt='greenhouseimg' />

            </div>
            <div className={`col-12 mb-5`}>
              <div className={` ${cascading.tabcardstyle}`} data-aos="zoom-in-up"  data-aos-offset="200">
                <div className={`row`}>
                  <div className={`col-lg-6 mb-3 px-0 ${cascading.leftside}`}>
                    <h3 className={`${cascading.tabhead}`}>Environment Feature</h3>

                    <ul className={`${cascading.listsec}`}>
                      {houseenv.map((e, i) => (
                        <li className={`d-flex align-items-center gap-4`}>
                          <p className={`${cascading.listcount}`}>{i > 9 ? i + 1 : "0" + (i + 1)}</p>
                          <p className={`${cascading.listdesc}`}>{e.desc}</p>
                        </li>
                      ))}

                    </ul>

                  </div>

                  <div className={`col-lg-6 mb-3 px-0`}>
                    <h3 className={`${cascading.tabhead}`}>Sustainability feature</h3>
                    <ul className={`${cascading.listsec}`}>
                      {houseSus.map((e, i) => (
                        <li className={`d-flex align-items-center gap-4`}>
                          <p className={`${cascading.listcount}`}>{i > 9 ? i + 1 : "0" + (i + 1)}</p>
                          <p className={`${cascading.listdesc}`}>{e.desc}</p>
                        </li>
                      ))}

                    </ul>
                  </div>

                </div>

              </div>

            </div>



          </div>

        </div>

      </section>
      <section id="SMART GREENHOUSE Self Sustainable village" className={`${cascading.housesec} ${cascading.sectionend} mb-5`}>
        <div className={`container customcontainer`}>
          <div className={`row `}>
            <div className={`col-12 mb-5`}>
              <div className={`${cascading.secbanner}`} data-aos="fade-up"  data-aos-offset="200">
                <img src={Images.houseprojectbanner} className={`img-fluid ${cascading.secbannerimg}`} alt='houseprojectbanner' />
                <div className={`${cascading.contentsec}`}>
                  <h2 className={`${cascading.sechead}`}>PROJECTS</h2>
                  <p className={`${cascading.secdesc}`}>SMART GREENHOUSE</p>
                </div>

              </div>
            </div>
            <div className={`col-12 mb-5`}>
              <img src={Images.houseprojectimg} className={`img-fluid ${cascading.greenhouseimg}`} alt='greenhouseimg' />

            </div>
            <div className={`col-12 mb-5`}>
              <div className={` ${cascading.tabcardstyle}`} data-aos="zoom-in-up"  data-aos-offset="200">
                <div className={`row`}>
                  <div className={`col-lg-6 mb-3 px-0 ${cascading.leftside}`}>
                    <h3 className={`${cascading.tabhead}`}>Environment Feature</h3>

                    <ul className={`${cascading.listsec}`}>
                      {houseenv1.map((e, i) => (
                        <li className={`d-flex align-items-center gap-4`}>
                          <p className={`${cascading.listcount}`}>{i > 9 ? i + 1 : "0" + (i + 1)}</p>
                          <p className={`${cascading.listdesc}`}>{e.desc}</p>
                        </li>
                      ))}

                    </ul>

                  </div>

                  <div className={`col-lg-6 mb-3 px-0`}>
                    <h3 className={`${cascading.tabhead}`}>Sustainability feature</h3>
                    <ul className={`${cascading.listsec}`}>
                      {houseSus1.map((e, i) => (
                        <li className={`d-flex align-items-center gap-4`}>
                          <p className={`${cascading.listcount}`}>{i > 9 ? i + 1 : "0" + (i + 1)}</p>
                          <p className={`${cascading.listdesc}`}>{e.desc}</p>
                        </li>
                      ))}

                    </ul>
                  </div>

                </div>

              </div>

            </div>



          </div>

        </div>

      </section>
      <section id="MY KITCHEN GARDEN Self Sustainable Project" className={`${cascading.kitchensec} ${cascading.sectionend} mb-5`}>
        <div className={`container customcontainer`}>
          <div className={`row `}>
            <div className={`col-12 mb-5`}>
              <div className={`${cascading.secbanner}`} data-aos="fade-up"  data-aos-offset="200">
                <img src={Images.houseprojectbanner} className={`img-fluid ${cascading.secbannerimg}`} alt='houseprojectbanner' />
                <div className={`${cascading.contentsec}`}>
                  <h2 className={`${cascading.sechead}`}>PROJECTS</h2>
                  <p className={`${cascading.secdesc}`}>MY KITCHEN GARDEN</p>
                </div>

              </div>
            </div>
            <div className={`col-12 mb-5`}>
              <p className={`${cascading.bluehead} text-center`}>Self Sustainable Project</p>
            </div>
            <div className={`col-12 mb-5`}>
            <div className={` ${cascading.tabcardstyle} ${cascading.kitchenimgsec}`}>
              <div className={` row `}>

            
                  <div className={`col-lg-4 mb-3`}>
                  {kitchenimg.filter((e,i) => i < 2).map((e,i) => (
                    <img src={e} className={`img-fluid ${cascading.kitchenimgset} ${i == 0 && cascading.miniimg} ${i == 1 && cascading.largeimg} mb-3`} alt='kitchenimg' />
                  ))}

                  </div>
               
                
                  <div className={`col-lg-4 mb-3`}>
                  {kitchenimg.filter((e,i) => i > 1 && i < 4).map((e,i) => (
                    <img src={e} className={`img-fluid ${i == 1 && cascading.miniimg} ${i == 0 && cascading.largeimg} ${cascading.kitchenimgset} mb-3`} alt='kitchenimg' />

                  ))}
                  </div>
               
                 
                  <div className={`col-lg-4 mb-3`}>
                  {kitchenimg.filter((e,i) => i > 3).map((e,i) => (
                    <img src={e} className={`img-fluid ${i == 0 && cascading.miniimg} ${i == 1 && cascading.largeimg} ${cascading.kitchenimgset} mb-3`} alt='kitchenimg' />
                  ))}
              

                  </div>
                  </div>
              
              </div>
            </div>

            <div className={`col-12 mb-5`}>
              <div className={` ${cascading.tabcardstyle}`} data-aos="zoom-in-up"  data-aos-offset="200">
                <div className={`row`}>
                  <div className={`col-lg-6 mb-3 px-0 ${cascading.leftside}`}>
                    <h3 className={`${cascading.tabhead}`}>Environment Feature</h3>

                    <ul className={`${cascading.listsec}`}>
                      {kitchenenv.map((e, i) => (
                        <li className={`d-flex align-items-center gap-4`}>
                          <p className={`${cascading.listcount}`}>{i > 9 ? i + 1 : "0" + (i + 1)}</p>
                          <p className={`${cascading.listdesc}`}>{e.desc}</p>
                        </li>
                      ))}

                    </ul>

                  </div>

                  <div className={`col-lg-6 mb-3 px-0`}>
                    <h3 className={`${cascading.tabhead}`}>Sustainability feature</h3>
                    <ul className={`${cascading.listsec}`}>
                      {kitchenSus.map((e, i) => (
                        <li className={`d-flex align-items-center gap-4`}>
                          <p className={`${cascading.listcount}`}>{i > 9 ? i + 1 : "0" + (i + 1)}</p>
                          <p className={`${cascading.listdesc}`}>{e.desc}</p>
                        </li>
                      ))}

                    </ul>
                  </div>

                </div>

              </div>

            </div>



          </div>

        </div>

      </section>
      <section id="BIO FUEL Self Sustainable Project" className={`${cascading.kitchensec} ${cascading.sectionend} mb-5`}>
        <div className={`container customcontainer`}>
          <div className={`row `}>
            <div className={`col-12 mb-5`}>
              <div className={`${cascading.secbanner}`} data-aos="fade-up"  data-aos-offset="200">
                <img src={Images.houseprojectbanner} className={`img-fluid ${cascading.secbannerimg}`} alt='houseprojectbanner' />
                <div className={`${cascading.contentsec}`}>
                  <h2 className={`${cascading.sechead}`}>PROJECTS</h2>
                  <p className={`${cascading.secdesc}`}>MY KITCHEN GARDEN</p>
                </div>

              </div>

            </div>
            <div className={`col-12 mb-5`}>
              <img src={Images.kitchensecimg1} className={`img-fluid ${cascading.kitchensecimg}`} alt='kitchensecimg' />

            </div>
            <div className={`col-12 mb-5`}>
              <img src={Images.kitchensecimg2} className={`img-fluid ${cascading.kitchensecimg}`} alt='kitchensecimg' />

            </div>



          </div>

        </div>

      </section>
      <section id="SMART WASTE WATER TREATMENT" className={`${cascading.watersec} ${cascading.sectionend} mb-5`}>
        <div className={`container customcontainer`}>
          <div className={`row `}>
            <div className={`col-12 mb-5`}>
              <div className={`${cascading.secbanner}`} data-aos="fade-up"  data-aos-offset="200">
                <img src={Images.houseprojectbanner} className={`img-fluid ${cascading.secbannerimg}`} alt='water' />
                <div className={`${cascading.contentsec}`}>
                  <h2 className={`${cascading.sechead}`}>PROJECTS</h2>
                  <p className={`${cascading.secdesc}`}>SMART WASTE WATER TRE </p>
                </div>

              </div>
            </div>
            <div className={`col-12 mb-5`}>
              <img src={Images.waterimg1} className={`img-fluid ${cascading.waterimg}`} alt='waterimg' />

            </div>
            <div className={`col-12 mb-5`}>
              <div className={` ${cascading.tabcardstyle}`} data-aos="zoom-in-up"  data-aos-offset="200">
                <div className={`row`}>
                  <div className={`col-lg-6 mb-3 px-0 ${cascading.leftside}`}>
                    <h3 className={`${cascading.tabhead}`}>Environment Feature</h3>

                    <ul className={`${cascading.listsec}`}>
                      {waterenv.map((e, i) => (
                        <li className={`d-flex align-items-center gap-4`}>
                          <p className={`${cascading.listcount}`}>{i > 9 ? i + 1 : "0" + (i + 1)}</p>
                          <p className={`${cascading.listdesc}`}>{e.desc}</p>
                        </li>
                      ))}

                    </ul>

                  </div>

                  <div className={`col-lg-6 mb-3 px-0`}>
                    <h3 className={`${cascading.tabhead}`}>Sustainability feature</h3>
                    <ul className={`${cascading.listsec}`}>
                      {waterSus.map((e, i) => (
                        <li className={`d-flex align-items-center gap-4`}>
                          <p className={`${cascading.listcount}`}>{i > 9 ? i + 1 : "0" + (i + 1)}</p>
                          <p className={`${cascading.listdesc}`}>{e.desc}</p>
                        </li>
                      ))}

                    </ul>
                  </div>

                </div>

              </div>

            </div>



          </div>

        </div>

      </section>
      <section id="Smart Community Smart Toilet" className={`${cascading.watersec} ${cascading.sectionend} mb-5`}>
        <div className={`container customcontainer`}>
          <div className={`row `}>
            <div className={`col-12 mb-5`}>
              <div className={`${cascading.secbanner}`} data-aos="fade-up"  data-aos-offset="200">
                <img src={Images.houseprojectbanner} className={`img-fluid ${cascading.secbannerimg}`} alt='water' />
                <div className={`${cascading.contentsec}`}>
                  <h2 className={`${cascading.sechead}`}>PROJECTS</h2>
                  <p className={`${cascading.secdesc}`}>SMART WASTE WATER TRE </p>
                </div>

              </div>
            </div>
            <div className={`col-12 mb-5`}>
              <img src={Images.waterimg2} className={`img-fluid ${cascading.waterimg}`} alt='waterimg' />

            </div>




          </div>

        </div>

      </section>
      <section id="BLUE ICEE GIANT WORLD" className={`${cascading.giantsec} ${cascading.sectionend} mb-5`}>
        <div className={`container customcontainer`}>
          <div className={`row `}>
            <div className={`col-12 mb-5`}>
              <div className={`${cascading.secbanner}`} data-aos="fade-up"  data-aos-offset="200">
                <img src={Images.giantbanner} className={`img-fluid ${cascading.secbannerimg}`} alt='aboutbanner' />
                <div className={`${cascading.contentsec}`}>
                  <h2 className={`${cascading.sechead}`}>Blue Icee Giant World</h2>
                  <p className={`${cascading.secdesc}`}>Self Sustainable Temple</p>
                </div>

              </div>
            </div>

            <div className={`col-12 mb-5`}>
              <div className={`row`}>
                {giantcont.map((e) => (
                  <div className={`col-md-6 col-lg-4 mb-3`}>
                    <div className={`${cascading.giantframe}`}>
                      <img src={e.img} className={`img-fluid ${cascading.giantframeinimg}`} alt={e.name} />

                    </div>


                  </div>
                ))}


              </div>

            </div>
            <div className={`col-12 mb-5`}>
              <div className={` ${cascading.tabcardstyle}`} data-aos="zoom-in-up"  data-aos-offset="200">
                <div className={`row`}>
                  <div className={`col-lg-6 mb-3 px-0 ${cascading.leftside}`}>
                    <h3 className={`${cascading.tabhead}`}>Environment Feature</h3>

                    <ul className={`${cascading.listsec}`}>
                      {giantenv.map((e, i) => (
                        <li className={`d-flex align-items-center gap-4`}>
                          <p className={`${cascading.listcount}`}>{i > 9 ? i + 1 : "0" + (i + 1)}</p>
                          <p className={`${cascading.listdesc}`}>{e.desc}</p>
                        </li>
                      ))}

                    </ul>

                  </div>

                  <div className={`col-lg-6 mb-3 px-0`}>
                    <h3 className={`${cascading.tabhead}`}>Sustainability feature</h3>
                    <ul className={`${cascading.listsec}`}>
                      {giantSus.map((e, i) => (
                        <li className={`d-flex align-items-center gap-4`}>
                          <p className={`${cascading.listcount}`}>{i > 9 ? i + 1 : "0" + (i + 1)}</p>
                          <p className={`${cascading.listdesc}`}>{e.desc}</p>
                        </li>
                      ))}

                    </ul>
                  </div>

                </div>

              </div>

            </div>



          </div>

        </div>

      </section>
      <section className={`${cascading.giantsec1} ${cascading.sectionend} mb-5`}>
        <div className={`container customcontainer`}>
          <div className={`row ${cascading.insidesec} mb-5`}>
            <div className={`col-12 mb-5`}>
              <div className={`${cascading.secbanner}`} data-aos="fade-up"  data-aos-offset="200">

                <div className={`${cascading.contentsec}`}>
                  <h2 className={`${cascading.sechead}`}>Blue Icee Giant World</h2>
                  <p className={`${cascading.secdesc}`}>Sustainable Lake & Themed Park</p>
                </div>

              </div>
            </div>
            <div className={`col-12 mb-5`}>
              <div className={`row`}>
                <div className={`col-lg-6 mb-3`}>
                  <div className={`${cascading.giantcard}`}>
                    <div className={`row ${cascading.giantrow} align-items-center`}>
                      <div className={`col-md-4 ${cascading.imgsec} mb-3`}>
                        <img src={Images.lake} className={`img-fluid ${cascading.giantgif}`} alt='giantgif1' />

                      </div>
                      <div className={`offset-md-1 col-md-7`}>
                        <p className={`${cascading.head}`}>Giant lake</p>
                        <p className={`${cascading.desc}`}>Lorem ipsum dolor sit amet consectetur. Id magna ante faucibus faucibus faucibus nibh ultricies tortor ornare. Viverra venenatis adipiscing senectus dictum. Pharetra quis iaculis hendrerit at lacus nunc. Dignissim dolor ipsum faucibus nulla nibh eget sit. Ut senectus ultricies cras adipiscing. Convallis morbi nisl varius non venenatis lectus eros et cras. Turpis diam.</p>
                      </div>
                    </div>




                  </div>

                </div>
                <div className={`col-lg-6 mb-3`}>
                  <div className={`${cascading.giantcard}`}>
                    <div className={`row align-items-center`}>
                      <div className={`col-md-4 ${cascading.imgsec} mb-3`}>
                        <img src={Images.themepark} className={`img-fluid ${cascading.giantgif}`} alt='giantgif1' />

                      </div>
                      <div className={`offset-md-1 col-md-7`}>
                        <p className={`${cascading.head}`}>Theme Park</p>
                        <p className={`${cascading.desc}`}>Lorem ipsum dolor sit amet consectetur. Id magna ante faucibus faucibus faucibus nibh ultricies tortor ornare. Viverra venenatis adipiscing senectus dictum. Pharetra quis iaculis hendrerit at lacus nunc. Dignissim dolor ipsum faucibus nulla nibh eget sit. Ut senectus ultricies cras adipiscing. Convallis morbi nisl varius non venenatis lectus eros et cras. Turpis diam.</p>
                      </div>
                    </div>




                  </div>

                </div>

              </div>

            </div>
            <div className={`col-12 mb-5`}>
              <div className={` ${cascading.tabcardstyle}`} data-aos="zoom-in-up"  data-aos-offset="200">
                <div className={`row`}>
                  <div className={`col-lg-6 mb-3 px-0 ${cascading.leftside}`}>
                    <h3 className={`${cascading.tabhead}`}>Environment Feature</h3>

                    <ul className={`${cascading.listsec}`}>
                      {giantenv1.map((e, i) => (
                        <li className={`d-flex align-items-center gap-4`}>
                          <p className={`${cascading.listcount}`}>{i > 9 ? i + 1 : "0" + (i + 1)}</p>
                          <p className={`${cascading.listdesc}`}>{e.desc}</p>
                        </li>
                      ))}

                    </ul>

                  </div>

                  <div className={`col-lg-6 mb-3 px-0`}>
                    <h3 className={`${cascading.tabhead}`}>Sustainability feature</h3>
                    <ul className={`${cascading.listsec}`}>
                      {giantSus1.map((e, i) => (
                        <li className={`d-flex align-items-center gap-4`}>
                          <p className={`${cascading.listcount}`}>{i > 9 ? i + 1 : "0" + (i + 1)}</p>
                          <p className={`${cascading.listdesc}`}>{e.desc}</p>
                        </li>
                      ))}

                    </ul>
                  </div>

                </div>

              </div>

            </div>



          </div>

        </div>

      </section>
      <section id="OUR ENVIRONMENT" className={`${cascading.environmentsec} ${cascading.sectionend} mb-5`}>
        <div className={`container customcontainer`}>
          <div className={`row `}>
            <div className={`col-12 mb-5`}>
              <div className={`${cascading.secbanner}`} data-aos="fade-up"  data-aos-offset="200">
                <img src={Images.envbanner} className={`img-fluid ${cascading.secbannerimg}`} alt='envbanner' />
                <div className={`${cascading.contentsec}`}>
                  <h2 className={`${cascading.sechead}`}>AN ENVIRONMENT WORTH LIVING IN</h2>
                  <p className={`${cascading.secdesc}`}>Blue Icee Giant Mini World</p>
                </div>

              </div>
            </div>
          </div>
          <Accordion defaultActiveKey="1" className={`${cascading.accordionsec}`}>
            <div className={`row`}>

              <div className={`col-lg-6 mb-3`}>
                {living.filter((e, i) => i < 5).map((e, i) => (
                  <Accordion.Item eventKey={i}>
                    <Accordion.Header>

                      <img src={e.img} className={`img-fluid ${cascading.accimg} me-4`} alt={e.title} />
                      {e.title}</Accordion.Header>
                    <Accordion.Body>
                      <ul className={`${cascading.acclist}`}>
                        {e.list.map((e, i) => (
                          <li><img src={Images.marker} className={`img-fluid ${cascading.markerimg} me-3`} alt={e} /> {e}</li>
                        ))}

                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </div>
              <div className={`col-lg-6 mb-3`}>
                {living.filter((e, i) => i > 4).map((e, i) => (
                  <Accordion.Item eventKey={5 + i}>
                    <Accordion.Header>

                      <img src={e.img} className={`img-fluid ${cascading.accimg} me-4`} alt={e.title} />
                      {e.title}</Accordion.Header>
                    <Accordion.Body>
                      <ul className={`${cascading.acclist}`}>
                        {e.list.map((e, i) => (
                          <li><img src={Images.marker} className={`img-fluid ${cascading.markerimg} me-3`} alt={e} /> {e}</li>
                        ))}

                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </div>
            </div>
          </Accordion>

        </div>
      </section>
      <section id="CONTACT US" className={`${cascading.contactsec} ${cascading.sectionend}`}>
        <div className={`container customcontainer`}>
          <div className={`row `}>
            <div className={`col-12 mb-5`}>
              <div className={`${cascading.secbanner}`} data-aos="fade-up"  data-aos-offset="200">
                <img src={Images.contactbanner} className={`img-fluid ${cascading.secbannerimg}`} alt='envbanner' />
                <div className={`${cascading.contentsec}`}>
                  <h2 className={`${cascading.sechead}`}>Contact Us</h2>
                  <p className={`${cascading.secdesc}`}>Get in touch with us today and let us help you with any questions or inquiries you may have.</p>
                </div>

              </div>
            </div>
            <div className={`col-lg-8 m-auto mb-5`}>
              <div className={`row`}>
                <div className={` col-md-4 mb-3`}>
                  <div className={`${cascading.contactcard}`}>
                    <IoMail />
                    <a href='mailto:' >demo@gmail.com</a>
                  </div>

                </div>

                <div className={` col-md-4  mb-3`}>
                  <div className={`${cascading.contactcard}`}>
                    <FaPhoneAlt />
                    <a href=''>+91 987456310</a>
                  </div>

                </div>
                <div className={`  col-md-4  mb-3`}>
                  <div className={`${cascading.contactcard}`}>
                    <FaLocationDot />
                    <a href=''>Get Location</a>
                  </div>

                </div>
              </div>



            </div>
            <hr className='mb-0'/>
            <div className={`col-lg-8 m-auto mb-5`}>
              <div className={`${cascading.cardformsec}`}>
                <div className={`row`}>
                  <div className={`col-md-6 mb-3`}>
                    <div className={`${cascading.formcard}`}>

                      <label for="exampleInputEmail1" className={`form-label`}>Full Name</label>
                      <input type="text" className={`form-control`} id="exampleInputEmail1" aria-describedby="emailHelp" placeholder='Type here' />



                    </div>

                  </div>
                  <div className={`col-md-6 mb-3`}>
                    <div className={`${cascading.formcard}`}>

                      <label for="exampleInputEmail1" className={`form-label`}>Full Email</label>
                      <input type="email" className={`form-control`} id="exampleInputEmail1" aria-describedby="emailHelp" placeholder='Type here' />



                    </div>

                  </div>
                  <div className={`col-md-12 mb-3`}>
                    <div className={`${cascading.formcard}`}>

                      <label for="exampleInputEmail1" className={`form-label`}>Your Message</label>
                      <textarea  className={`form-control`} id="exampleInputEmail1" placeholder='Type here' />



                    </div>

                  </div>
                  <div className={`col-md-12 text-center mb-3 mt-4`}>
                  <button className={`${cascading.submitbtn} btn`}> Submit</button>
                   </div>
                </div>

              </div>

            </div>
          </div>


        </div>
      </section>
      <section className={`${cascading.socialsec}`}>

        <div className={`${cascading.socialcard}`}>
          <p className='mb-0'>Stay Connected</p>
           <a target='_blank' href="https://www.facebook.com/">
           <FaFacebook /></a>
           <a target='_blank' href="https://x.com/">
           <FaXTwitter /></a>
           <a target='_blank' href="https://in.linkedin.com/">
           <FaLinkedin /></a>
        </div>

      </section>
      </>
}
    </div>
  )
}
