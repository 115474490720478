import React, { useEffect, useState } from 'react';
import cascading from '../Assests/css/header.module.css';
import { Button, Container, Nav, NavDropdown, Navbar, Offcanvas } from 'react-bootstrap';
import Images from '../Assests/images/images';
import { NavLink } from 'react-router-dom';

export default function Header() {
    const [isScrolled, setIsScrolled] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 10);
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);
  return (
    <header className={`${cascading.header}`}>
    <Navbar className={isScrolled ? `${cascading.navbar} ${cascading.navbaractive}` : `${cascading.navbar}`} key="xl" expand="xl" >
                <Container className='customcontainer'>
                    <Navbar.Brand href="/"><img src={Images.bluelogo} className={`img-fluid ${cascading.logoimg}`} alt='images' /></Navbar.Brand>
                    <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-xl`} className={`${cascading.toggler} `} onClick={() => setMenuOpen(!menuOpen)} />
                    <Navbar.Offcanvas
                        id={`offcanvasNavbar-expand-lg`}
                        aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
                        placement="end"
                        restoreFocus={false}
                        className={`${cascading.offcanvas} `}
                        show={menuOpen}
                        onHide={() => setMenuOpen(!menuOpen)}
                    >
                        <Offcanvas.Header closeButton >
                          
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                        <Nav className={`ms-xl-auto me-xl-auto align-items-xl-center ${cascading.navlist}`}>
                                <NavLink to="https://blueiceegiant.maticz.in/">Home </NavLink>
                                <NavLink to="https://honeybluedex.maticz.in/swap">HBLU</NavLink>
                                <NavLink to="https://honeybluelockedstaging.maticz.in/staking">Locked staking</NavLink>
                                <NavLink to={`https://honeyblue.maticz.in/games`}>Game</NavLink>
                                <NavLink to="#">Faq</NavLink>



                            </Nav>
                            {/* <button className={`btn themebtn`}>Connect Wallet</button> */}
                        </Offcanvas.Body>   
                    </Navbar.Offcanvas>
                </Container>
            </Navbar>   
    </header>
  )
}
