const Images ={
    bluelogo:require('../images/bluelogo.png'),
    aboutbanner:require('../images/aboutbanner.png'),
    aboutimg1:require('../images/aboutimg1.png'),
    aboutimg2:require('../images/aboutimg2.png'),
    missionbanner:require('../images/missionbanner.png'),
    missionimg :require('../images/missionbanner.png'),
    property :require('../images/property.png'),
    infra :require('../images/infra.png'),
    community :require('../images/community.png'),
    devbanner :require('../images/devbanner.png'),
    devimg :require('../images/devimg.png'),
    environment:require('../images/environment.png'),
    tech:require('../images/tech.png'),
    people:require('../images/people.png'),
    architechure:require('../images/architechure.png'),
    combanner :require('../images/combanner.png'),
    vegetables :require('../images/vegetables.png'),
    shrimps :require('../images/shrimps.png'),
    fish :require('../images/fish.png'),
    combanner1 :require('../images/combanner1.png'),
    greenhousebanner :require('../images/greenhousebanner.png'),
    greenhouseimg :require('../images/greenhouseimg.png'),
    houseprojectbanner :require('../images/houseprojectbanner.png'),
    houseprojectimg :require('../images/houseprojectimg.png'),
    kitchensecimg1 :require('../images/kitchenimg1.png'),
    kitchensecimg2 :require('../images/kitchenimg2.png'),
    waterimg1:require('../images/waterimg1.png'),
    waterimg2:require('../images/waterimg2.png'),
    giantbanner:require('../images/giantbanner.png'),
    giant1:require('../images/giant1.png'),
    giant2:require('../images/giant2.png'),
    giant3:require('../images/giant3.png'),
    lake:require('../images/lake.webp'),
    themepark:require('../images/themepark.webp'),
    accimg1:require('../images/accimg1.png'),
    accimg2:require('../images/accimg2.png'),
    accimg3:require('../images/accimg3.png'),
    accimg4:require('../images/accimg4.png'),
    accimg5:require('../images/accimg5.png'),
    accimg6:require('../images/accimg6.png'),
    accimg7:require('../images/accimg7.png'),
    accimg8:require('../images/accimg8.png'),
    accimg9:require('../images/accimg9.png'),
    marker:require('../images/marker.png'),
    envbanner:require('../images/envbanner.png'),
    contactbanner:require('../images/contactbanner.png'),
    bannergif:require('../images/bannergif.webp'),
    mergeimg:require('../images/mergeimg.png'),
    kitchenidea1 :require('../images/kitchenidea1.png'),
    kitchenidea2 :require('../images/kitchenidea2.png'),
    kitchenidea3 :require('../images/kitchenidea3.png'),
    kitchenidea4 :require('../images/kitchenidea4.png'),
    kitchenidea5 :require('../images/kitchenidea5.png'),
    kitchenidea6 :require('../images/kitchenidea6.png'),
    gradlayer1 :require('../images/gradlayer1.png'),
    introbg :require('../images/introbg.png'),
    snow :require('../images/snow.webp'),
    whitelogo :require('../images/whitelogo.png'),
    bluelogo1 :require('../images/bluelogo1.png'),
    vision1 :require('../images/vision1.png'),
    vision2 :require('../images/vision2.png'),
    vision3 :require('../images/vision3.png'),
}

export default Images;