import React, { useState } from 'react'
import Offcanvas from 'react-bootstrap/Offcanvas';
import cascading from '../Assests/css/sidemenu.module.css'
import Images from '../Assests/images/images';
export default function Sidemenu(props) {
  const [projectmenu,setProjectmenu] = useState(false)
  const [menuname,setMenuname]= useState([
    {
      title:"HOME",
      submenu:[],
    },
    {
      title:"ABOUT US",
      submenu:[],
    },
    {
      title:"VISION",
      submenu:[],
    },
    {
      title:"MISSION",
      submenu:[],
    },
    {
      title:"OUR SOLUTIONS",
      submenu:[],
    },
    {
      title:"OUR DEVELOPMENT",
      submenu:[],
    },
    {
      title:"PROJECTS",
      submenu:["SMART community","SMART green house","SMART GREENHOUSE Self Sustainable village","MY KITCHEN GARDEN Self Sustainable Project","BIO FUEL Self Sustainable Project","SMART WASTE WATER TREATMENT","Smart Community Smart Toilet"],
    },
    {
      title:"BLUE ICEE GIANT WORLD",
      submenu:[],
    },
    {
      title:"OUR ENVIRONMENT",
      submenu:[],
    },
    {
      title:"CONTACT US",
      submenu:[],
    },
  ])
  const mousehoverfn = (title) => {
    if(title === "PROJECTS"){
      setProjectmenu(true)
    }else{
      setProjectmenu(false)
    }
  }
  return (
    <div>
        <Offcanvas show={props.show} onHide={props.handleClose} className={`${cascading.offcanvas}`}>
          <img src={Images.gradlayer1} className={`img-fluid ${cascading.gradlayer1}`} alt='images' />
          <img src={Images.gradlayer1} className={`img-fluid ${cascading.gradlayer2}`} alt='images' />
        <Offcanvas.Header closeButton>
          {/* <Offcanvas.Title>Offcanvas</Offcanvas.Title> */}
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className={`${cascading.sidemenu} d-flex`}>
            <div className={`${cascading.leftsidemenu}`}>
            <ul className={`${cascading.menulist}`}>
              {menuname.map((e, index) => (
                 <li id={e.title} className={`${cascading.titlemenu} ${projectmenu && e.title === "PROJECTS" && cascading.titlemenuactive}`} onClick={(id) => props.getid(id)} onMouseEnter={()=>mousehoverfn(e.title)} >{e.title}</li>
              ))}
              
               </ul>
            </div>
            <div className={`${cascading.rightsidemenu}`}>
              {projectmenu &&
            <ul className={`${cascading.menulist}`}>
              {menuname.filter(item => item.title === "PROJECTS").map((e, index) => (
                e.submenu.map((sub) => (
                  <li id={sub} className={`${cascading.titlemenu}`} onClick={(id) => props.getid(id)}>{sub}</li>
                ))
                 
              ))}
               </ul>
}
            </div>
          </div>
         
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  )
}
